import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"
import { Script } from "gatsby"

export default function Contact() {
  const [hubspotLoaded, setHubspotLoaded] = React.useState(false)

  React.useEffect(() => {
    if (hubspotLoaded && window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "21791923",
        formId: "7eae9c00-4302-46c2-9486-8b98a2254c9d",
        target: "#hubspotForm",
      })
    }
  }, [hubspotLoaded])

  return (
    <>
      <Script
        src="//js.hsforms.net/forms/embed/v2.js"
        onLoad={() => setHubspotLoaded(true)}
      />
      <Layout>
        <div className="p-contact">
          <div className="p-contact__inner c-inner-primary">
            <div className="l-oldContent">
              <div className="c-title01">
                <h1 className="p-contact__title__text c-title01__text">
                  お問い合わせ
                </h1>
              </div>
              <div className="l-oldContent__inner">
                <div id="hubspotForm"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export function Head() {
  return <Seo title="CONTACT" description="" pathname="" />
}
